import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
// import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import storefile from "./store";
import { ValidationProvider, ValidationObserver } from "vee-validate";

// axios
window.axios = require("axios");
import { Service } from "axios-middleware";

Vue.config.productionTip = false;

// isPublicならログインなしで見れる
router.beforeEach((to, from, next) => {
  // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
  if (
    to.matched.some((record) => !record.meta.isPublic) &&
    !store.state.setting.userToken
  ) {
    next({ path: "/", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

Vue.use(Vuex);
//Vuex設定
const store = new Vuex.Store({
  modules: {
    setting: storefile,
  },
  plugins: [
    createPersistedState({
      key: "HmcAppsAdmin",
      paths: ["setting.userToken", "setting.ref"],
      storage: window.sessionStorage,
    }),
  ],
});

const service = new Service(window.axios);
service.register({
  onRequest(config) {
    if (typeof store.getters["setting/getUserToken"] == "string") {
      config.headers[
        "Authorization"
      ] = `Bearer ${store.getters["setting/getUserToken"]}`;
      delete config.auth;
    }
    return config;
  },
  // onResponseError(err) {
  //   if (err.response.status === 401) {
  //     firebase
  //       .auth()
  //       .signOut()
  //       .then(() => {
  //         this.$router.push("/login");
  //         this.$store.commit("setting/setToken", "");
  //       })
  //       .catch(() => {
  //         this.$router.push("/login");
  //       });
  //   }
  // },
  //     // firebase.auth().onAuthStateChanged(() => {
  //     //   firebase
  //     //     .auth()
  //     //     .signOut()
  //     //     .then(() => {
  //     //       router.push("/login");
  //     //     });
  //     // });
  //   }
  // }
});

// firebase
const config = {
  apiKey: "AIzaSyCLetJEFE-hdlGw-tTojBxZDk6KTBdD2Fk",
  authDomain: "aaaaa-8498f.firebaseapp.com",
  projectId: "aaaaa-8498f",
  storageBucket: "aaaaa-8498f.appspot.com",
  messagingSenderId: "73558464630",
  appId: "1:73558464630:web:6618c90926dcb3f781e757",
  measurementId: "G-VH5Q87J38H",
};

// eslint-disable-next-line no-unused-vars
initializeApp(config);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
