<template>
  <div class="d-flex justify-center">
    <v-container>
      <v-row justify="center">
        <v-col cols="6">
          <ValidationObserver mode="lazy" v-slot="{ invalid }">
          <v-form v-model="valid">
            <ValidationProvider
                          rules="required|email"
                          name="メールアドレス"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="form.email"
                            label="メールアドレス"
                            outlined
                            color="#89e2f0"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required"
                          name="パスワード"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="form.password"
                            label="パスワード"
                            outlined
                            type="password"
                            color="#89e2f0"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
          </v-form>
          <div class="d-flex justify-center">
            <v-btn
            color="primary"
            :disabled="invalid"
            :loading="loading"
            @click="login()"
            >ログインする</v-btn
          >
          </div>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
</style>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { email } from "vee-validate/dist/rules";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
extend("required", {
  ...required,
  message: "{_field_}は必須です"
});
extend("email", {
  ...email,
  message: "メールアドレスの形式になっていません"
});

export default {
  name: 'Login',
  data(){
    return {
      valid:false,
      loading:false,
      form:{
        email:"",
        password:"",
      }
    }
  },
  methods:{
    /**
     * ログインメソッド
     */
    login(){

const auth = getAuth();
signInWithEmailAndPassword(auth, this.form.email, this.form.password)
  .then((userCredential) => {
    const user = userCredential.user;
    console.log(user);
    let token = user.getIdToken();
    this.$store.commit("setting/setToken",token);

    this.$router.push("/home");
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
  });

    }
  },
}
</script>
