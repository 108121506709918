export default {
  namespaced: true,
  state: {
    userToken: "",
    ref: "",
    contactForm: {
      lastName: "",
      firstName: "",
      lastKname: "",
      firstKname: "",
      phone: "",
      email: "",
      contactContent: "",
      requirement: "",
      contactMethod: "0"
    }
  },
  getters: {
    getUserToken: state => {
      return state.userToken;
    },
    getRef: state => {
      return state.ref;
    }
  },
  mutations: {
    setToken(state, accessToken) {
      state.userToken = accessToken;
    },
    setRef(state, ref) {
      state.ref = ref;
    }
  }
};
/**
・モジュールの中のデータ取り出しのやりかた
■Getterの方法
this.$store.getters['setting/getSoundFlg']

コミットのやり方検証中
Getterと同じなら、
this.$store.commit('setting/setToken',値);と、module名をパスに含んであげればよい気がする。

*/
